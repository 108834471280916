<script setup>
import { computed } from 'vue';

const emit = defineEmits(['close']);

const props = defineProps({
    show: {
        type: Boolean,
        default: false,
    },
    toolbarProps: {
        type: Object,
        default: () => {},
    },
    fullHeight: {
        type: Boolean,
        default: false,
    },
    cardColor: {
        type: String,
        default: 'surface',
    },
});

const height = computed(() => {
    if (props.fullHeight) {
        return window.innerHeight - 48;
    } else {
        return null;
    }
});
</script>

<template>
    <v-dialog
        class="!tw-container xl:!tw-max-w-screen-lg"
        :height="height"
        :scrollable="true"
        :model-value="show"
        :close-on-back="true"
        @update:model-value="emit('close')"
    >
        <v-card :color="cardColor">
            <v-toolbar v-if="!!$slots.toolbar" v-bind="toolbarProps">
                <slot name="toolbar"></slot>
            </v-toolbar>
            <v-card-title v-if="!!$slots.title" class="tw-pl-[24px]">
                <slot name="title"></slot>
            </v-card-title>
            <v-card-text v-if="!!$slots.content" class="tw-pt-2">
                <slot name="content"></slot>
            </v-card-text>
            <v-card-actions v-if="!!$slots.footer" class="tw-p-6 tw-py-2">
                <slot name="footer"></slot>
            </v-card-actions>
            <slot name="append"></slot>
        </v-card>
    </v-dialog>
</template>
