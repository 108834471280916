<script setup>
import DialogModal from '@/Components/DialogModal.vue';
import { computed, ref, watch } from 'vue';
import { useAxios } from '@vueuse/integrations/useAxios';
import { useForm } from '@inertiajs/vue3';
import CheckboxField from '@/Components/CheckboxField.vue';
import TextField from '@/Components/TextField.vue';
import SaveButton from '@/Components/SaveButton.vue';
import { mdiClose } from '@mdi/js';

const props = defineProps({
    show: {
        type: Boolean,
        required: true,
    },
    ticket: {
        type: Object,
        required: true,
    },
    edit: {
        type: Boolean,
        default: false,
    },
});
const emit = defineEmits(['close', 'updated']);

const id = ref(null);
const { data, isFinished, execute } = useAxios(
    route('api.ticket.special-order.show', { ticket: props.ticket.id }),
    {},
    {
        immediate: false,
    },
);

const times = computed(() => data.value?.times);
watch(
    () => props.show,
    (value) => {
        if (value && !isFinished.value) {
            execute();
        }
    },
);

const specialOrderForm = useForm({
    is_completed: false,
    billed_at: null,
});

watch(data, (value) => {
    if (value) {
        id.value = value.id;
        specialOrderForm.defaults({
            is_completed: value.completed_at !== null,
            billed_at: value.billed_at,
        });
        specialOrderForm.reset();

        emit('updated', value);
    }
});

const loading = ref(false);
const submitSpecialOrderForm = async () => {
    loading.value = true;
    const response = await axios.put(
        route('api.special-order.update', { special_order: data.value.id }),
        specialOrderForm.data(),
    );

    specialOrderForm.defaults({
        is_completed: response.data.data.completed_at !== null,
        billed_at: response.data.data.billed_at,
    });

    id.value = response.data.data.id;
    specialOrderForm.reset();
    specialOrderForm.isDirty = false;

    emit('updated', response.data.data);

    loading.value = false;
};
const cancel = () => {
    specialOrderForm.reset();
    specialOrderForm.isDirty = false;
    emit('close');
};

const formatDate = (date) => {
    if (date === null) {
        return null;
    }

    return moment(date).format('DD.MM.YYYY HH:mm');
};

const diff = (start, stop) => {
    if (start === null || stop === null) {
        return null;
    }

    // create diff in seconds and format as HH:mm:ss
    const diff = moment(stop).diff(moment(start), 'seconds');

    return formatDiff(diff);
};

const formatDiff = (diff) =>
    `${Math.floor(diff / 3600)
        .toString()
        .padStart(2, '0')}:${Math.floor((diff % 3600) / 60)
        .toString()
        .padStart(2, '0')}:${(diff % 60).toString().padStart(2, '0')}`;

const diffSum = computed(() =>
    times.value.reduce((acc, time) => {
        if (time.stop === null) {
            return acc;
        }

        const diff = moment(time.stop).diff(moment(time.start), 'seconds');
        return acc + diff;
    }, 0),
);
</script>

<template>
    <DialogModal
        :toolbar-props="{ color: 'surface' }"
        :show
        @close="$emit('close')"
    >
        <template v-if="isFinished" #title>
            <div class="tw-mt-2 tw-flex tw-justify-between">
                <h6 class="tw-font-sans tw-text-lg">
                    SAN <strong>#{{ id }}</strong>
                </h6>
                <v-icon
                    class="tw-text-gray-400"
                    :icon="mdiClose"
                    @click="cancel"
                ></v-icon>
            </div>
        </template>
        <template #content>
            <div
                v-if="!isFinished"
                class="tw-flex tw-h-64 tw-items-center tw-justify-center"
            >
                <v-progress-circular
                    indeterminate
                    size="large"
                    color="primary"
                ></v-progress-circular>
            </div>
            <div v-else>
                <v-row>
                    <v-col>
                        <TextField
                            v-model="specialOrderForm.billed_at"
                            :readonly="!edit"
                            type="date"
                            hide-details="auto"
                            label="Abgerechnet am"
                        ></TextField>
                    </v-col>
                    <v-col>
                        <CheckboxField
                            v-model="specialOrderForm.is_completed"
                            :readonly="!edit"
                            label="Abgeschlossen"
                            hide-details="auto"
                        >
                        </CheckboxField>
                    </v-col>
                </v-row>
                <v-row v-if="times !== null">
                    <v-col>
                        <ul
                            v-for="time in times"
                            :key="time.id"
                            class="tw-list-none"
                        >
                            <li>
                                <div class="tw-flex tw-w-full tw-items-center">
                                    <div class="tw-mr-2 tw-font-bold">
                                        {{ time.username }}
                                    </div>
                                    <div class="tw-mr-4 tw-text-sm">
                                        <span>{{
                                            formatDate(time.start)
                                        }}</span>
                                        <span v-if="time.stop">
                                            - {{ formatDate(time.stop) }}</span
                                        >
                                    </div>
                                    <v-spacer></v-spacer>
                                    <div v-if="time.stop">
                                        <span class="tw-font-extrabold">
                                            {{ diff(time.start, time.stop) }}
                                        </span>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <div
                            class="tw-my-4 tw-border-0 tw-border-t tw-border-solid tw-border-t-gray-400"
                        ></div>
                        <div class="tw-flex tw-w-full tw-justify-between">
                            <v-spacer></v-spacer>
                            <div class="tw-font-extrabold">
                                {{ formatDiff(diffSum) }}
                            </div>
                        </div>
                    </v-col>
                </v-row>
                <v-row v-if="edit">
                    <v-col
                        ><SaveButton
                            class="tw-mr-2"
                            type="button"
                            :loading
                            :disabled="!specialOrderForm.isDirty"
                            @click="submitSpecialOrderForm"
                        ></SaveButton>
                        <v-btn
                            type="button"
                            color="danger"
                            :disabled="!specialOrderForm.isDirty || loading"
                            @click="cancel"
                            >{{ $t('Cancel') }}</v-btn
                        >
                    </v-col>
                </v-row>
            </div>
        </template>
    </DialogModal>
</template>

<style scoped></style>
