<script setup>
import { computed, ref, watch } from 'vue';
import { useForm } from '@inertiajs/vue3';
import CustomerSelect from '@/Components/CustomerSelect.vue';
import DeliveryNoteSelect from '@/Components/DeliveryNoteSelect.vue';

const props = defineProps({
    ticket: {
        type: Object,
        required: true,
    },
    specialOrder: {
        type: Object,
        required: true,
    },
    responsibles: {
        type: Object,
        required: true,
    },
    meta: {
        type: [Object, null],
        required: true,
    },
    edit: {
        type: Boolean,
        required: true,
    },
    lists: {
        type: Object,
        required: true,
    },
});
const emit = defineEmits(['create:special-order']);

const responsible = computed(
    () => props.responsibles[props.ticket.responsible],
);

const updateTicketForm = useForm({
    ticket_type_id: props.ticket.ticket_type_id,
    responsible: props.ticket.responsible,
    status: props.ticket.status,
    customer_number: props.ticket.customer_number,
    delivery_note_number: props.ticket.delivery_note_number,
});
const deliveryNote = ref({
    delivery_note_number: props.ticket.delivery_note_number,
    customer_number: props.ticket.customer_number,
});

watch(deliveryNote, (value) => {
    if (value !== null) {
        updateTicketForm.customer_number = value.customer_number;
        updateTicketForm.delivery_note_number = value.delivery_note_number;
    }
});

watch(
    () => updateTicketForm.customer_number,
    (value) => {
        if (deliveryNote.value.customer_number !== value) {
            deliveryNote.value = null;
        }
    },
);

const reset = () => {
    updateTicketForm.reset();
    deliveryNote.value = {
        delivery_note_number: props.ticket.delivery_note_number,
        customer_number: props.ticket.customer_number,
    };
};
const submit = () => {
    updateTicketForm.put(route('ticket.update', props.ticket.id));
};

const metaTableEntries = computed(() => {
    const metaTable = [];
    if (props.meta === null) {
        return metaTable;
    }
    Object.keys(props.meta.subtype)
        .filter((key) => key.endsWith('_label'))
        .forEach((key) => {
            const metaKey = key.replace('_label', '');
            if (props.meta[metaKey]?.length > 0) {
                metaTable.push({
                    key: props.meta.subtype[key],
                    value: props.meta[metaKey],
                });
            }
        });

    return metaTable;
});

const createdAt = computed(() =>
    moment(props.ticket.created_at).format('DD.MM.YYYY'),
);
</script>

<template>
    <v-table density="compact">
        <tbody>
            <tr>
                <th class="tw-text-left tw-font-bold">
                    {{ $t('Type') }}
                </th>
                <td v-if="!edit">{{ ticket.type.name }}</td>
                <td v-else>
                    <v-select
                        v-model="updateTicketForm.ticket_type_id"
                        :error-messages="updateTicketForm.errors.ticket_type_id"
                        color="primary"
                        density="compact"
                        variant="plain"
                        :items="lists.types"
                        item-title="name"
                        item-value="id"
                        hide-details="auto"
                    ></v-select>
                </td>
            </tr>
            <tr>
                <th class="tw-text-left tw-font-bold">Sonderauftragsnummer</th>
                <td>
                    <span>{{ specialOrder ? specialOrder.id : 'Nein' }}</span>
                    <v-btn
                        v-if="specialOrder || edit"
                        class="tw-ml-2"
                        size="small"
                        color="info"
                        variant="tonal"
                        @click="emit('create:special-order')"
                        >SAN</v-btn
                    >
                </td>
            </tr>
            <tr v-if="ticket.type.customer_number_label">
                <th class="tw-text-left tw-font-bold">
                    {{ ticket.type.customer_number_label }}
                </th>
                <td v-if="!edit">
                    {{ ticket.customer_number }}
                </td>
                <td v-else>
                    <CustomerSelect
                        v-model="updateTicketForm.customer_number"
                        hide-details="auto"
                        bg-color="surface"
                        density="compact"
                        variant="plain"
                    />
                </td>
            </tr>
            <tr v-if="ticket.type.delivery_note_number_label">
                <th class="tw-text-left tw-font-bold">
                    {{ ticket.type.delivery_note_number_label }}
                </th>
                <td v-if="!edit">
                    {{ ticket.delivery_note_number }}
                </td>
                <td v-else>
                    <DeliveryNoteSelect
                        v-model="deliveryNote"
                        hide-details="auto"
                        bg-color="surface"
                        density="compact"
                        variant="plain"
                    />
                </td>
            </tr>
            <tr>
                <th class="tw-text-left tw-font-bold">
                    {{ $t('Responsible') }}
                </th>
                <td v-if="!edit">{{ responsible }}</td>
                <td v-else>
                    <v-select
                        v-model="updateTicketForm.responsible"
                        :error-messages="updateTicketForm.errors.responsible"
                        color="primary"
                        density="compact"
                        variant="plain"
                        :items="lists.responsibles"
                        item-title="name"
                        item-value="value"
                        clearable
                        hide-details="auto"
                    />
                </td>
            </tr>
            <tr>
                <th class="tw-text-left tw-font-bold">
                    {{ $t('Status') }}
                </th>
                <td v-if="!edit">{{ ticket.status }}</td>
                <td v-else>
                    <v-select
                        v-model="updateTicketForm.status"
                        :error-messages="updateTicketForm.errors.status"
                        color="primary"
                        density="compact"
                        variant="plain"
                        :items="lists.statuses"
                        hide-details="auto"
                    ></v-select>
                </td>
            </tr>
            <tr>
                <th class="tw-text-left tw-font-bold">
                    {{ $t('Created at') }}
                </th>
                <td>{{ createdAt }}</td>
            </tr>
            <tr>
                <th class="tw-text-left tw-font-bold">
                    {{ $t('Created by') }}
                </th>
                <td>{{ ticket.content[0].user.username }}</td>
            </tr>
            <tr v-for="metaRow in metaTableEntries" :key="metaRow.id">
                <th class="tw-text-left tw-font-bold">
                    {{ metaRow.key }}
                </th>
                <td>{{ metaRow.value }}</td>
            </tr>
            <tr>
                <td></td>
                <td class="tw-w-80 tw-text-right">
                    <v-btn
                        v-show="updateTicketForm.isDirty"
                        class="tw-mr-2"
                        size="small"
                        variant="text"
                        :disabled="updateTicketForm.processing"
                        @click="reset"
                    >
                        {{ $t('Reset') }}</v-btn
                    >
                    <v-btn
                        v-show="updateTicketForm.isDirty"
                        color="primary"
                        size="small"
                        :loading="updateTicketForm.processing"
                        @click="submit"
                        >{{ $t('Save') }}</v-btn
                    >
                </td>
            </tr>
        </tbody>
    </v-table>
</template>

<style scoped></style>
