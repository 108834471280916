<script setup>
const state = defineModel({
    type: Boolean,
    default: false,
});
</script>

<template>
    <v-btn :variant="!state ? 'tonal' : 'flat'" @click="state = !state">
        <slot v-if="!$slots.alternative || !state"></slot>
        <slot v-else name="alternative"></slot>
    </v-btn>
</template>

<style scoped></style>
